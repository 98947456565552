import { Button } from "@chakra-ui/button";
import {Redirect} from 'react-router-dom';
import React, { useContext } from 'react';
import {AuthContext} from 'providers/AuthProvider/AuthProvider';
import {
  CCreateElement,
  CSidebar,
  CSidebarNav,
  CSidebarNavItem,
  CSidebarNavDropdown,
} from "@coreui/react";
import useNav from './useNav';

type SidebarProps = {
  show: boolean | "" | "responsive";
};

const Sidebar: React.FC<SidebarProps> = ({ show }) => {
  const {user, logout} = useContext(AuthContext);
  const _nav = useNav();

  return (
    <CSidebar show={show}>
      <CSidebarNav>
        <CCreateElement
          items={_nav}
          components={{ CSidebarNavItem, CSidebarNavDropdown}}
        />
        <Button
        style={{color:"#3c4b64", width:"100%", position:"absolute",
        bottom: "5%", borderRadius: "0px"}}
        onClick={()=>{
          logout();
          return <Redirect to='/login' />
        }}
        >
          Log out
        </Button>
      </CSidebarNav>
    </CSidebar>
  );
};
export default Sidebar;
