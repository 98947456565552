import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCollapse,
  CDataTable,
  CFormGroup,
  CInputCheckbox,
  CLink,
  CModal,
  CPagination,
  CSelect,
  CLabel,
  CInput
} from "@coreui/react";
import { CModalHeader, CModalBody, CModalTitle } from '@coreui/react';
import { perPageOptions } from "config";
import { isEmpty } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { SortValue } from "./types";
import CIcon from "@coreui/icons-react";
import { deleteMethod, put, MEDIA_URL } from 'api/index';
import { user } from 'providers/AuthProvider/AuthProvider';
import { HStack } from "@chakra-ui/layout";
import React, { FormEvent, useState } from 'react';
import {FaImage} from 'react-icons/fa';
import {useToast} from '@chakra-ui/react';

type PaginatedTableProps = {
  fields: any[];
  items: any[];
  loading: boolean;
  pagination?: boolean;
  pages?: number;
  perPage?: number;
  activePage?: number;
  setPerPage?: (perPage: number) => void;
  setActivePage?: (perPage: number) => void;
  select?: (id: string) => void;
  unselect?: (id: string) => void;
  selected?: any;
  allSelected?: boolean;
  selectAll?: () => void;
  reset?: () => void;
  actions?: JSX.Element;
  sortBy?: (sortValue: SortValue) => void;
  sortValue?: SortValue;
  user?: user;
  total?: number;
  setCurrent?: (id: number) => void;
};
const PaginatedTable: React.FC<PaginatedTableProps> = ({
  fields,
  items,
  perPage,
  activePage,
  pagination,
  select,
  unselect,
  selected,
  setPerPage,
  setActivePage,
  actions,
  sortBy,
  sortValue,
  user,
  setCurrent,
  total
}) => {
  const location = useLocation();

  //method for accepting and rejecting orders
  const resolveOrder = async (id: number, status: boolean) => {
    put(`subscription/order/${id}`, { status }, undefined, user?.accessToken)
      .then((res: any) => {
        if (res && res.id) {
          toast({
            title: "Resolved successfully!",
            status: "success",
            duration: 4000,
            isClosable: true
        })
        }
        setCurrent && setCurrent(id);
      })
      .catch(() => {
        toast({
          title: "Something went wrong..",
          status: "error",
          duration: 4000,
          isClosable: true
      })
      });
  }
  //control the visibility for the edit modal
  const [modalInfo, setModalInfo] = useState({
    visible: false,
    itemId: -1
  });

  //toasting
  const toast = useToast();

  //Password 
  const [password, setPassword] = useState("")

  const handlesubmit = async (e: FormEvent) => {
    e.preventDefault();
    const endpoint = "user/" + `${modalInfo.itemId}` + "/password";
    if (password.length < 8) {
      toast({
        title: "Password must at least contain 8 characters",
        status: "error",
        duration: 4000,
        isClosable: true
    })
      setModalInfo({ ...modalInfo, visible: false });
      return;
    }
    //body 
    const body = {
      password: `${password}`
    }
    put(endpoint, body, undefined, user?.accessToken).then((res: any) => {
      toast({
        title: "User's password changed successfully!",
        status: "success",
        duration: 4000,
        isClosable: true
    })
      setModalInfo({ ...modalInfo, visible: false });
    }).catch(() => () => {
      toast({
        title: "Couldn't change user password..",
        status: "error",
        duration: 4000,
        isClosable: true
    })
      setModalInfo({ ...modalInfo, visible: false });
    })
  }
  const cancelSubscription = async (activeSubsscription: any) => {
    const endpoint = `subscription/${activeSubsscription.id}`;
    await deleteMethod(endpoint, user?.accessToken);
    toast({
      title: "Subscription cancelled successfully!",
      status: "success",
      duration: 4000,
      isClosable: true
  })
  }
  
  return (
    <CCard>
      {selected ? (
        <CCollapse show={!isEmpty(selected)}>
          <CCardHeader>
            {actions}
            <small
              style={{
                float: "right",
              }}
            >
              {Object.keys(selected).length} Selected
            </small>
          </CCardHeader>
        </CCollapse>
      ) : (
        <></>
      )}
      <CCardBody>
        {pagination ? (
          <CSelect
            style={{ width: 200, marginInlineStart: "auto" }}
            className="my-2"
            value={perPage}
            onChange={(e) => setPerPage?.(e.currentTarget.value)}
          >
            {perPageOptions.map((p, idx) => (
              <option key={idx} value={p}>
                {p}
              </option>
            ))}
          </CSelect>
        ) : null}
        <CDataTable
          fields={fields}
          columnHeaderSlot={
            {
              // _select: (
              //   <CFormGroup variant="checkbox" className="checkbox">
              //     <CInputCheckbox
              //       checked={allSelected}
              //       onChange={(e: any) => {
              //         e.target.checked ? selectAll?.() : unselectAll?.();
              //       }}
              //     />
              //   </CFormGroup>
              // ),
            }
          }
          scopedSlots={{
            _select: (item: any) => (
              <td>
                <CFormGroup variant="checkbox" className="checkbox">
                  <CInputCheckbox
                    checked={item._selected || false}
                    onChange={(e: any) => {
                      e.target.checked
                        ? select?.(item.id)
                        : unselect?.(item.id);
                    }}
                  />
                </CFormGroup>
              </td>
            ),
            proveUrl: (item: any) => (
              <td>
                <CLink href={`${MEDIA_URL}/${item.proveUrl}`} target='_blank'>
                  <FaImage size={28} color="#2a94f7"/>
                </CLink>
              </td>
            ),
            nationalityProofUrl: (item: any) => (
              <td>
                <CLink href={`${MEDIA_URL}/${item.nationalityProofUrl}`} target='_blank'>
                  <FaImage size={28} color="#2a94f7"/>
                </CLink>
              </td>
            ),
            _edit: (item: any) => (
              <td>
                <CLink
                  color="primary"
                  // className="btn btn-primary btn-sm"
                  to={`${location.pathname}/${item.id}/edit`}
                >
                  Edit
                </CLink>
              </td>
            ),
            accept: (item: any) => (
              <td>
                <CButton color='success' onClick={item.status === 'Pending' ? () => resolveOrder(item.id, true) : undefined} disabled={item.status !== 'Pending'}>
                  Accept
                </CButton>
              </td>
            ),
            reject: (item: any) => (
              <td>
                <CButton color='danger' onClick={item.status === 'Pending' ? () => resolveOrder(item.id, false) : undefined} disabled={item.status !== 'Pending'}>
                  Reject
                </CButton>
              </td>
            ),
            actions: (item: any) => (
              <td>
                <HStack>
                  {
                    user?.role.name && ['Admin', 'Reviewer'].includes(user.role.name) &&
                    <CButton color='primary' style={{ width: "5.3rem" }} onClick={(e: any) => {
                      setModalInfo({
                        itemId: item.id,
                        visible: true
                      });
                    }}>
                      Change password
                    </CButton>
                  }
                  <CButton color='danger' style={{ width: "6.35rem" }} onClick={item.activeSubscription ? () => cancelSubscription(item.activeSubscription): undefined} disabled={!item.activeSubscription || !('id' in item.activeSubscription)}>
                    Cancel subscription
                  </CButton>
                </HStack>
              </td>
            )
          }}
          sorter={{ external: true }}
          sorterValue={sortValue}
          onSorterValueChange={(sortValue: SortValue) => sortBy?.(sortValue)}
          items={items}
        />
        {modalInfo.visible &&
          <CModal show={modalInfo.visible} onClose={() => setModalInfo({ ...modalInfo, visible: false })} style={{ padding: '20px 10px', maxHeight: '500px', overflowY: 'scroll' }} className='scrollable'>
            <CModalHeader closeButton>
              <CModalTitle>Change Password</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CLabel>New password</CLabel>
              <CInput id="newPassword" name="newPassword" onChange={(e: FormEvent) => {
                const target = e.target as HTMLInputElement;
                setPassword(target.value);
              }}></CInput>
            </CModalBody>
            <div className='pt-3 text-center'>
              <CButton color='success' size={'lg'} type={'submit'} onClick={handlesubmit}>Save</CButton>
            </div>
          </CModal>}
        {pagination ? (
          <div className='d-flex justify-content-center'>
            <CPagination
              className="my-2"
              pages={total}
              activePage={activePage}
              onActivePageChange={(e: any) => {
                setActivePage?.(e);
              }}
            />
          </div>
        ) : null}
      </CCardBody>
    </CCard>
  );
};
export default PaginatedTable;
