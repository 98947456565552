import { CFormGroup, CInputFile, CLabel } from "@coreui/react";

const FileInput: React.FC<CInputFile> = ({ label, ...props }) => {
  return (
    <CFormGroup>
      <CLabel htmlFor={props.id}>{label}</CLabel>
      <CInputFile {...props} />
    </CFormGroup>
  );
};
export default FileInput;
