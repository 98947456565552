import React from 'react';
import {CMSModel} from './CMSModels';
import {CButton} from '@coreui/react';
import {deleteMethod} from 'api/index';
import {useContext} from 'react';
import {AuthContext} from 'providers/AuthProvider/AuthProvider';
import {useToast} from '@chakra-ui/react';

interface ModelDeleteProps {
    item: CMSModel,
    deleteEndpoint: string;
    refetchRecords: () => void;
    populated?: boolean;
}

const ModelDelete: React.FC<ModelDeleteProps> = ({item, deleteEndpoint, refetchRecords, populated}) => {

    //toast messages
    const toast = useToast();

    //current user
    const {user} = useContext(AuthContext);
    const deleteRecord = async () => {
        //replace the place holder id with the id of the item
        const endPoint = deleteEndpoint.replace('{id}', `${item.id!}`);
        const success: any = await deleteMethod(endPoint, user?.accessToken);
        if (success) {
            toast({
                title: "Successfully deleted record!",
                status: "success",
                duration: 4000,
                isClosable: true
            })
            refetchRecords();
        }
        else {
            toast({
                title: "Failed to delete record",
                status: "error",
                duration: 4000,
                isClosable: true
            })
        }
    }

    return (
        <td>
            <CButton color='danger' onClick={deleteRecord} disabled={populated}>
                Remove
            </CButton>
        </td>
    )

}

export default ModelDelete;