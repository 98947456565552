import React, { FormEvent, useState, useEffect } from 'react';
import { CModal, CButton, CModalHeader, CModalBody, CModalTitle, CInput, CInputFile, CInputCheckbox, CLabel, CTextarea, CSelect } from '@coreui/react';
import { CMSModel } from './CMSModels';
import { post, getAmazonConfig, uploadFile } from 'api/index';
// @ts-ignore
import S3 from 'react-aws-s3';
import { FaPlusCircle, FaTimes } from 'react-icons/fa';
import { useContext, useRef } from 'react';
import { AuthContext } from 'providers/AuthProvider/AuthProvider';
import { IconButton } from '@chakra-ui/react';
import {useToast} from "@chakra-ui/react";
import { isTemplateExpression } from 'typescript';
import { send } from 'process';


interface ModelEditProps {
    locale: string,
    item: CMSModel;
    inputFields: Array<{ key: string, type: string, options?: string[], required: boolean, multiple?: boolean, label?: string, disabled?: boolean }>
    putEndpoint: string
    populated?: boolean;
    putMethod?: string;
    postEditEndpoint?: string,
    refetchRecords: () => void;
}


const ModelEdit: React.FC<ModelEditProps> = ({ item, locale, inputFields, putEndpoint, putMethod, refetchRecords }) => {

    //Client to use to upload files to AWS S3
    const S3Client = new S3(getAmazonConfig());

    //number of media files
    const [mediaFs, setMediaFs] = useState(1);

    //when a file is uploaded
    const [doneUrl, setDoneUrl] = useState<{name?: string; url?: string; key?: string}>({name: '', url: ''});
    const prevDoneUrl = useRef<{name?: string; url?: string; key?: string}>({name: '', url: ''});

    //temp for add inputs
    const [temp, setTemp] = useState<any>({});

    //toasting messages
    const toast = useToast();

    //current user
    const { user } = useContext(AuthContext);

    //update the values for current item
    useEffect(() => {
        if (item) setData(item);
        setTAdded({val: false, article: false});
    }, [item])

    //module name 
    const moduleName = putEndpoint.split("/")[0];

    //saving the data entered by user
    const [data, setData] = useState<any>(item);

    //type of video
    const [videoType, setType] = useState("upload");

    //track the upload progress
    const [uploading, setUploading] = useState<any>({});

    //indicates that translation is added to data
    const [TAdded, setTAdded] = useState({article: false, val: false});

    //uploaded boolean
    const [uploaded, setUploaded] = useState(false);

    const [uploadBoolean, setUploadBoolean] = useState(false);

    //processing state
    const [processing, setProcessing] = useState(false);

    //control the visibility for the edit modal
    const [visible, setVisible] = useState(false);

    //media type
    const [mediaType, setMediaType] = useState("");

    //map for input labels that is not quite good
    const labelMap: any = {
        'mediaUrl': 'Media File',
        'imageUrl': 'Image',
        'videoUrl': 'Video',
        'url': 'Audio File',
        'mediaFiles': 'Audio Files',
        "videoTFT": "Video",
        "imageTFT": 'Image'
    }
    //function for handling changes for inputs of type text
    const handleTextChange = (e: FormEvent) => {
        const target = e.target as HTMLInputElement;
        if (target.value === "video") {
            setMediaType("video")
        }
        else if (target.value === "audio") {
            setMediaType("media");
        }
        if (target.name.includes('mediaF')) {
            const targetKey = target.getAttribute('data-kop');
            if (data.mediaFiles) {
                setData((data: any) => {
                    const dataClone = [...data.mediaFiles];
                    const searching = dataClone.find((en: any) => en.id === targetKey);
                    var returnData;
    
                    if (searching) {
                        searching.name = target.value;
                        returnData = { ...data, mediaFiles: dataClone };
                    }
                    else {
                        const newMedia = { id: targetKey, name: target.value, url: "" };
                        returnData = { ...data, mediaFiles: [...data.mediaFiles, newMedia] };
                    }

                    return returnData;
                })
            }
            else {
                setData((data: any) => ({ ...data, mediaFiles: [{ id: targetKey, name: target.value, url: "" }] }));
            }
            return;
        }
        setData((data: any) => ({ ...data, [target.name]: target.value }));
    }
    const handleChange = (e: FormEvent) => {
        const target = e.target as HTMLInputElement;
        target.value === "browse" && target.name === "video" ? setType("browse") : setType("upload");

    }
    //function for handling changes for inputs of type files
    const handleMediaChange = async (e: FormEvent) => {
        const target = e.target as HTMLInputElement;
        if (uploading[target.name] && !target.name.includes('mediaF')) return;
        if (!target.name.includes('mediaF') && target.files) {
            setUploading((prevUploading: any) => {
                return { ...prevUploading, [target.name]: true }
            });
        }
        if (target.name.includes('mediaF') && target.files && target.files.length === 1) {
            const targetKey = target.getAttribute('data-kop');
            if (uploading[`mediaF-${targetKey}`]) return;
            setUploadBoolean(true);
            setProcessing(true);
            setUploading((prevUploading: any) => {
                return { ...prevUploading, [target.name]: true }
            });
            const url = await uploadFile(target.files[0], user?.accessToken);
            setUploadBoolean(false);
            setUploading((prevUploading: any) => {
                return { ...prevUploading, [target.name]: false }
            });
            setUploaded(true);
            setProcessing(false);
            setDoneUrl({name: target.name, url, key: targetKey || undefined});
        }
        else {
            if (target.files && target.files.length > 0) {
                setUploading((prevUploading: any) => {
                    return { ...prevUploading, [target.name]: true }
                });
                setProcessing(true);
                // const data = await S3Client.uploadFile(file, file.name).catch(() => alert("Failed to upload image!"));
                const url = await uploadFile(target.files[0], user?.accessToken);
                setUploading((prevUploading: any) => {
                    return { ...prevUploading, [target.name]: false };
                });
                setProcessing(false);
                setDoneUrl({name: target.name, url});

            }
            else {
                setData({ ...data, [target.name]: target.value });
            }
        }

    }

    //handling upload completiong
    useEffect(() => {
        if (doneUrl.name && doneUrl.url) {
            //need to check that the done url was the dependency caused the trigger
            //data is a dependency to keep tracking only of it's value
            if (doneUrl === prevDoneUrl.current) return;
            prevDoneUrl.current = doneUrl;
            if (doneUrl.name.includes('mediaF')) {
                if (data.mediaFiles) {
                    setData((data: any) => {
                        const dataClone = [...data.mediaFiles];
                        const searching = dataClone.find((en: any) => en.id === doneUrl.key);
                        var returnData;
    
                        if (searching) {
                            searching.url = doneUrl.url;
                            returnData = { ...data, mediaFiles: dataClone };
                        }
                        else {
                            const newMedia = { id: doneUrl.key, name: "", url: doneUrl.url };
                            returnData = { ...data, mediaFiles: [...data.mediaFiles, newMedia] };
                        }
                        return returnData;
                    })
                }
                else {
                    setData((data: any) => ({ ...data, mediaFiles: [{ id: doneUrl.key, name: "", url: doneUrl.url }] }));
                }
            }
            else {
                setData({...data, [doneUrl.name]: doneUrl.url});
            }
        }
    }, [doneUrl, data])

    //function for handling changes for inputs of type checkbox
    const handleBooleanChange = (e: FormEvent) => {
        const target = e.target as HTMLInputElement;
        setData({ ...data, [target.name]: target.checked });
    }

    //function to handle input of type add (submit)
    const handleAddSubmit = (name: string) => {
        if (name === 'symbol' || name === 'place') {
            if (!temp.symbol || !temp.place) return;
            const translation = data.translations?.find((t: any) => t.locale === locale);
            let val = data['article'] ? [...data['article'], {symbol: temp['symbol'], place: temp['place']}] : 
            [...translation?.article, {symbol: temp['symbol'], place: temp['place']}];
            setData({ ...data, 'article': val });
            setTemp({ ...temp, place: '', symbol: '' });
            //Now it's added
            setTAdded({...TAdded, article: true});
        }
        else if(name === 'article'){
            var newVal;
             newVal = data['translations'][0]['article'] ? [...data['translations'][0]['article'], temp[name]] : [temp[name]]
            setData({ ...data, [name]: newVal});
            setTemp({ ...temp, [name]: '' });
        }
        else {
            const translation = data.translations?.find((t: any) => t.locale === locale) ? data.translations?.find((t: any) => t.locale === locale)[name] : [];
            const newValues = data[name] ? [...data[name], temp[name]] : [...translation, temp[name]];
            setData({ ...data, [name]: newValues });
            setTemp({ ...temp, [name]: '' });
            setTAdded({...TAdded, val: true});
        }
        setTimeout(() => document.querySelector('.op-add-cont')!.scrollTop = document.querySelector('.op-add-cont')!.scrollHeight, 0);
    }

    //function to handle input of type add (change)
    const handleAddChange = (e: FormEvent) => {
        const target = e.target as HTMLInputElement;
        setTemp({ ...temp, [target.name]: target.value });
    }

    //function to remove from input of type add
    const handleAddFilter = (name: string, key: string) => {
        var translations: any = [];
        if (data.translations) {
            translations = [...data.translations];
            //remove from translations
            const translation = translations.find((t: any) => t.locale === locale);
            if (translation) {
                translation[key] = translation[key].filter((val: string) => val !== name);
            }
        }
        const newValues = data[key]?.filter((val: string) => val !== name);
        setData({ ...data, [key]: newValues , translations});
    }

    //function to remove media
    const handleMediaFilter = (id: number, key: string) => {
        var translations: any = [];
        if (data.translations) {
            translations = [...data.translations];
            //remove from translations
            const translation = translations.find((t: any) => t.locale === locale);
            if (translation) {
                translation[key] = translation[key].filter((val: any) => val.id !== id);
            }
        }
        const newValues = data[key]?.filter((val: any) => val.id !== id);
        if (newValues) {
            setData({ ...data, [key]: newValues , translations});
        }
        else {
            setData({ ...data, translations});
        }
    }

    //A map that joins each type with it's suitable component
    const compMap = {
        'text': CInput,
        'file': CInputFile,
        'checkbox': CInputCheckbox,
        'date': CInput,
        'textArea': CTextarea
    }

    //A map that joins each type with it's suitable handler
    const handlersMap = {
        'text': handleTextChange,
        'file': handleMediaChange,
        'date': handleTextChange,
        'checkbox': handleBooleanChange,
        "textArea": handleTextChange
    }

    const videoTypesList = ['videoUrl', 'videoTFT', 'url'];
    const currentFileInvisible = ['mediaFiles', 'videoTFT', 'imageTFT'];
    //function that returns the suitable component
    const getInputComponent = (name: string, type: keyof typeof compMap & keyof typeof handlersMap, required: boolean, multiple?: boolean, disabled?: boolean) => {
        const InputComponent = compMap[type];
        var defaultValue: any = '';

        //check that name is equal to one of the keys on the object
        const keyName = name as keyof CMSModel & keyof CMSModel["translations"];
        defaultValue = item[keyName];
        if (!defaultValue && item.translations) {
            //Get the correct translations
            const translations = item.translations?.find(translation => translation.locale === locale);
            if (translations && typeof translations[keyName] === 'string') defaultValue = translations[keyName];
        }
        //Default values coming from media object
        if (!defaultValue && item.media) {
            const val = item.media.find((m: any) => m.type?.toLowerCase() === labelMap[name]?.toLowerCase());
            if (val && typeof val.url === 'string') {
                defaultValue = val.url;
            }
        }
        if (type !== 'file') {
            return <InputComponent id={name} name={name} onChange={handlersMap[type]} type={type} style={{ position: 'relative', height: type === 'textArea' ? '250px' : 'initial' }} defaultValue={defaultValue} defaultChecked={defaultValue} required={required} disabled={disabled} />
        }
        else {
            return <React.Fragment>
                {name !== 'mediaFiles' && name !== 'media' && <h6 className='h6'>Current: <a href={defaultValue}>{defaultValue}</a></h6>}
                {(videoTypesList.includes(name) || moduleName === "training" || (moduleName === "learning" && item.type === "video") || (mediaType === "video" || mediaType === 'audio')) &&
                    <div onChange={handleChange} style={{ marginBlock: "15px" }}>
                        {/* <h6>Current: <a href={defaultValue || data.media?.find((m: any) => m.type === 'video')?.url}>{defaultValue || data.media?.find((m: any) => m.type === 'video')?.url}</a></h6> */}
                        <CInput id={name} type="radio" value="upload" name="video" style={{ width: "20px", height: "auto", display: "inline" }} defaultChecked disabled={uploading[name]}/>Upload
                        <CInput id={name} type="radio" value="browse" name="video" style={{ width: "20px", height: "auto", display: "inline" }} disabled={uploading[name]}/>External Link
                    </div>
                }
                {(videoType === "browse" && (videoTypesList.includes(name) || moduleName === "training" || (moduleName === "learning" && item.type === "video") || (mediaType === "video"))) ?
                    <div>
                        <h6>Url:</h6>
                        <CInput id={name} name={name} onChange={handleMediaChange} required={required} multiple={multiple} placeholder="YouTube Url"></CInput>
                    </div> :
                    name === 'mediaFiles' ?
                        <>
                            <ul style={{padding: "0 30px"}}>
                                {
                                    item?.translations?.find((trans: any) => trans.locale === locale)?.mediaFiles?.map((mediaFile: any) => (
                                        <li className='mt-2' key={mediaFile.id}>
                                            <a href={mediaFile.url} target='_blank'>{mediaFile.name}</a>
                                            <IconButton size="sm" variant="ghost" margin="0 15px" onClick={() => handleMediaFilter(mediaFile.id, 'mediaFiles')} aria-label="Remove" icon={<FaTimes />}/>
                                        </li>
                                    ))
                                }
                            </ul>
                            <div className='mt-3'>
                                {
                                    [...Array(mediaFs).keys()].map((index) => (
                                        <div className='mt-3'>
                                            <CLabel>Name</CLabel>
                                            <CInput className='op-add-input' key={`mediaF-name-loc-${index}`} id={`mediaF-name-${index}`} name={`mediaF-name-${index}`} type="text" onChange={handleTextChange} data-kop={index} disabled={uploading[`mediaF-${index}`] || index < mediaFs - 1} />
                                            <div className='mt-2'>
                                                <InputComponent id={`mediaF-loc-${index}`} name={`mediaF-${index}`} onChange={handlersMap[type]} type={type} style={{ position: 'relative' }} data-kop={index} disabled={uploading[`mediaF-${index}`] || index < mediaFs - 1} />
                                            </div>
                                        </div>
                                    ))
                                }
                                <CButton color='success' size='md' className='mt-3' onClick={() => {setMediaFs(mediaFs + 1); setUploaded(false);}} disabled={!uploaded} style={{cursor: !uploaded ? 'not-allowed' : 'pointer'}}>Add File</CButton>
                            </div>
                        </>
                        :
                        <InputComponent id={name} name={name} onChange={handlersMap[type]} type={type} style={{ position: 'relative' }} multiple={multiple} disabled={uploading[name] || disabled} required={!defaultValue}/>
                }
                {uploading[name] && <h6 style={{ marginTop: '10px', padding: "0 10px" }}>Uploading...</h6>}
            </React.Fragment>
        }

    }

    //function to handle submitting the data
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const activeAdd = document.activeElement?.className.split(' ').includes('op-add-input');
        if (moduleName === "healing" && !data['affirmations']) {
            toast({
                title: "Please add affirmations!",
                status: "error",
                duration: 4000,
                isClosable: true
            })
            setVisible(false);
            setProcessing(false);
            return;
        }
        if (processing || activeAdd) return;
        setProcessing(true);
        //replace the id placeholder in the endpoint with the current record id
        const endPoint = putEndpoint.includes('{id}') ? putEndpoint.replace('{id}', `${item.id}`) : putEndpoint.replace('{slug}', `${item.slug}`);
        var sendData = data;
        const translations = Array.isArray(data.translations) ? [...data.translations] : undefined;
        if (translations) {
            const translation = translations.find((translation: any) => translation.locale === locale);
            if (translation) {
                var mediaFiles: any[] = [];
                if (translation?.mediaFiles) {
                    mediaFiles = data.mediaFiles ? [...translation.mediaFiles, ...data.mediaFiles] : translation.mediaFiles;
                    delete translation.mediaFiles;
                }
                sendData = { ...translation, ...data, mediaFiles }
            }
        } 
        if (item.name === "tap") {
            if ((!sendData.imageTFT || !sendData.videoTFT) && (!sendData.media.find((m: any) => m.type === 'video') || !sendData.media.find((m: any) => m.type === 'image'))) {
                toast({
                    title: "Couldn't update the record! Kindly add video and image",
                    status: "error",
                    duration: 4000,
                    isClosable: true
                })
                setVisible(false);
                setProcessing(false);
                return;
            }
        }
        if(sendData.article){
            //we need to find the other locale translation object if any so that it's not lost
            const otherTranslation = data.translations?.find((t: any) => t.locale !== locale);
            const currentTranslation = {article: sendData.article, locale};
            if (otherTranslation) {
                sendData.translations = [otherTranslation, currentTranslation];
            }
            else {
                sendData.translations = [currentTranslation];
            }
        }
        //Behavior is kinda different for TFT modules, so we handle it accordingly
        if (sendData.imageTFT || sendData.videoTFT) {
            //we need to find media of type video
            const videoMedia = sendData.videoTFT ? {url: sendData.videoTFT, type: 'video'} : data.media.find((m: any) => m.type === 'video');
            //we need to find media of type video
            const imageMedia = sendData.imageTFT ? {url: sendData.imageTFT, type: 'image'} : data.media.find((m: any) => m.type === 'image');
            const sendMedia = [];
            if (videoMedia) sendMedia.push(videoMedia);
            if (imageMedia) sendMedia.push(imageMedia);
            sendData = {...sendData, media: sendMedia};
        }
        if (moduleName === 'chakra') {
            post(endPoint, { ...sendData, locale: locale }, locale, 'PUT', user?.accessToken);
        }
        console.log(sendData);
        post(endPoint, { ...sendData, locale: locale }, locale, putMethod, user?.accessToken)
            .then((res: any) => {
                if (res?.id || Array.isArray(res)) {
                    toast({
                        title: "Successfully updated the record!",
                        status: "success",
                        duration: 4000,
                        isClosable: true
                    })
                    setVisible(false);
                    refetchRecords();
                    setProcessing(false);
                    document.querySelectorAll('input[type="file"]').forEach((input: any) => input.value = null);
                    document.querySelectorAll('.op-add-input').forEach((input: any) => input.value = null);
                    setMediaFs(1);
                }
                else {
                    toast({
                        title: "Couldn't update the record..",
                        status: "error",
                        duration: 4000,
                        isClosable: true
                    })
                    setVisible(false);
                    setProcessing(false);
                }
            }) 
    }
    return (
        <td>
            {!item.translations?.find(trans => trans.locale === locale || item.videoTFT === '' || item.imageTFT === '' || item.imageUrl === null || item.videoUrl === null) ?
                <CButton color='danger' onClick={() => setVisible(true)}>
                    Edit
            </CButton> :
                <CButton color='success' onClick={() => setVisible(true)}>
                    Edit
        </CButton>
            }
            <CModal show={visible} onClose={() => setVisible(false)} style={{ padding: '20px 10px', maxHeight: '500px', overflowY: 'scroll' }} className='scrollable' closeOnBackdrop={false}>
                <CModalHeader closeButton>
                    <CModalTitle>
                        <h4 className='font-weight-bold text-capitalize h4'>{item.slug ? item.slug : item.name ? item.name : item.frequency}</h4>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form className='pt-3' onSubmit={handleSubmit} key={item.id}>
                        {
                            inputFields.map((field) => {
                                if (item.name === "9g" && field.key === "imageTFT") {
                                    return null;
                                }
                                return (
                                    <div key={field.key} style={field.type === 'checkbox' ? { display: 'flex', justifyContent: 'space-between', } : {}} className='my-3'>
                                        <CLabel htmlFor={field.key} style={{ display: 'block', textTransform: 'capitalize', fontWeight: "bolder" }}>{field.label ? field.label : (moduleName === "training" && field.key === 'url') ? "Video File" : moduleName === 'learning' ? field.key : (labelMap[field.key] || field.key)}</CLabel>
                                        {
                                            field.type === 'add' ?
                                                <div>
                                                    <div style={{ maxHeight: '100px', overflowY: 'auto', paddingBottom: "10px" }} className='op-add-cont px-2'>
                                                        {
                                                            data.translations.find((trans: any) => trans.locale === locale) && data.translations.find((trans: any) => trans.locale === locale)[field.key]?.map((val: any) => {
                                                                if (typeof val === 'string') {
                                                                    if (!TAdded.val) return (
                                                                        <h6 key={val} className='text-secondary d-flex justify-content-between align-items-center h6'>
                                                                            {val}
                                                                            <FaTimes onClick={() => handleAddFilter(val, field.key)} cursor='pointer' />
                                                                        </h6>
                                                                    )
                                                                }
                                                                else {
                                                                    if (!TAdded.article) {
                                                                        return (
                                                                            <h6 key={val?.symbol} className='text-secondary d-flex justify-content-between align-items-center h6'>
                                                                                <span key={val?.place} className='text-secondary d-flex justify-content-between align-items-center'>
                                                                                    {val.symbol} - 
                                                                                    {val.place}
                                                                                </span>
                                                                                <span>
                                                                                    <FaTimes onClick={() => handleAddFilter(val, field.key)} cursor='pointer' />
                                                                                </span>
                                                                            </h6>
                                                                        )
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        {
                                                            data[field.key]?.map((val: any) => (
                                                                typeof val === 'string' ?
                                                                    <h6 key={val} className='text-secondary d-flex justify-content-between align-items-center h6'>
                                                                        {val}
                                                                        <FaTimes onClick={() => handleAddFilter(val, field.key)} cursor='pointer' />
                                                                    </h6> :
                                                                    <h6 key={val?.symbol} className='text-secondary d-flex justify-content-between align-items-center h6'>
                                                                        <span key={val?.place} className='text-secondary d-flex justify-content-between align-items-center h6'>
                                                                            {val?.symbol} - 
                                                                            {val?.place}
                                                                        </span>
                                                                        <span>
                                                                            <FaTimes onClick={() => handleAddFilter(val, field.key)} cursor='pointer' />
                                                                        </span>
                                                                    </h6>
                                                            ))
                                                        }
                                                    </div>
                                                    {item.name === "tap" ?
                                                        <div className='d-flex align-items-center'>
                                                            <CLabel style={{ marginRight: 4 }}>Symbol</CLabel>
                                                            <CInput id="symbol" name="symbol" onChange={handleAddChange} type="text" onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault();
                                                                    handleAddSubmit("symbol");
                                                                    document.getElementById('place')?.focus();
                                                                }
                                                            }} className='op-add-input' value={temp['symbol']}  />
                                                            <CLabel style={{ marginRight: 4, marginLeft: 4 }}>Place</CLabel>
                                                            <CInput id="place" name="place" onChange={handleAddChange} type="text" onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault();
                                                                    handleAddSubmit("place");
                                                                    document.getElementById('symbol')!.focus();
                                                                }
                                                            }} className='op-add-input' value={temp['place']} />
                                                            <CButton className='mx-2 p-0' onClick={() => handleAddSubmit("symbol")}>
                                                                <FaPlusCircle size={24} color='green' />
                                                            </CButton>
                                                        </div> :
                                                        <div className='d-flex align-items-center'>
                                                            <CInput data-add id={field.key} name={field.key} onChange={handleAddChange} type="text" onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault();
                                                                    handleAddSubmit(field.key);
                                                                }
                                                            }} className='op-add-input' value={temp[field.key]} />
                                                            <CButton className='mx-2 p-0' onClick={() => handleAddSubmit(field.key)}>
                                                                <FaPlusCircle size={24} color='green' />
                                                            </CButton>
                                                        </div>}

                                                </div>
                                                :
                                                field.type === 'select' ?
                                                    <CSelect className='text-capitalize' id={field.key} name={field.key} onChange={handleTextChange} type={field.type} required multiple={field.multiple}>
                                                        <optgroup className='text-capitalize' defaultValue={''}>
                                                            <option value={''}>Please select a(n) {field.key}</option>
                                                            {
                                                                field.options?.map((opt, index) => (
                                                                    <option value={opt} key={index}>{opt}</option>
                                                                ))
                                                            }
                                                        </optgroup>
                                                    </CSelect>
                                                    :
                                                    getInputComponent(field.key, (field.type === 'date' || field.type === 'text' || field.type === 'file' || field.type === 'checkbox' || field.type === 'textArea') ? field.type : 'text', field.required, field.multiple, field.disabled)
                                        }
                                    </div>
                                )
                            })
                        }
                        <div className='pt-3 text-center'>
                            <CButton color='success' size={'lg'} type={'submit'} disabled={uploadBoolean || processing} style={{cursor: uploadBoolean || processing ? 'not-allowed' : 'pointer'}}>Save</CButton>
                        </div>
                    </form>
                </CModalBody>
            </CModal>
        </td>
    )
}

export default ModelEdit;