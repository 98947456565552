import { CFooter } from "@coreui/react";
import Content from "../Content";
import Header from "../Header";
import Sidebar from "../Sidebar";
import useSidebarState from "./useSidebarState";
import {useContext} from 'react';
import {AuthContext} from 'providers/AuthProvider/AuthProvider';
import {Redirect} from 'react-router-dom';

const Layout: React.FC = () => {
  const { showSidebar, toggleSidebarMobile, toggleSidebar } = useSidebarState();

  //current active user
  const { user } = useContext(AuthContext);

  if (!user) return <Redirect to='/login' />

  return (
    <div className="c-app c-default-layout">
      <Sidebar show={showSidebar} />
      <div className="c-wrapper">
        <Header
          toggleSidebar={toggleSidebar}
          toggleSidebarMobile={toggleSidebarMobile}
        />
        <Content />
        <CFooter />
      </div>
    </div>
  );
};

export default Layout;
