import { CContainer, CFade } from "@coreui/react";
import { Route, Switch } from "react-router-dom";
import routes from "../../routes";

const Content: React.FC = () => {
  return (
    <main className="c-main">
      <CContainer fluid>
        <Switch>
          {routes.map((route, idx) => {
            return (
              route.component && (
                <Route key={idx} path={route.path} exact={route.exact}>
                  {
                    ({match}) => (
                      <CFade>
                        <route.component match={match}/>
                      </CFade>
                    )
                  }
                  {/* <CCard
                    style={{
                      minHeight: "calc(100vh - 162px)",
                    }}
                  > */}
                  {/* </CCard> */}
                </Route>
              )
            );
          })}
          {/* <Redirect from="/" to="/dashboard" /> */}
        </Switch>
      </CContainer>
    </main>
  );
};
export default Content;
