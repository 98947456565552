import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { LocaleProvider } from "./providers/LocaleProvider";
import AuthProvider from './providers/AuthProvider/AuthProvider';
import Layout from "./containers/Layout";
import Login from './containers/Login/Login';
import {ChakraProvider} from "@chakra-ui/react";

function App() {
  return (
    <LocaleProvider>
      <AuthProvider>
        <ChakraProvider>
          <Router>
            <Route path='/login' exact>
              <Login />
            </Route>
            <Layout />
          </Router>
        </ChakraProvider>
      </AuthProvider>
    </LocaleProvider>
  );
}

export default App;
