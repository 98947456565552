import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CSelect,
} from "@coreui/react";
import React, { useState, FormEvent, useEffect } from "react";
import { CFormGroup, CInput, CLabel } from "@coreui/react";
import { post, getAmazonConfig, uploadFile, getHealing } from '../../../api/index';
// @ts-ignore
import S3 from 'react-aws-s3';
import { CMSModel } from 'views/dashboard/ModelManagment/CMSModels';
import { useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider/AuthProvider';
import { FaPlusCircle, FaTimes } from 'react-icons/fa';
import {useToast, Text} from "@chakra-ui/react";
import useSelect from "components/PaginatedTable/useSelect";


type CreateModalProps = {
    btnProps: CButton;
    title: string;
    actions: JSX.Element;
    labels: Array<{ key: string, type: string, multiple?: boolean, options?: string[], label?: string }>;
    postEndpoint: string;
    refetchRecords: () => void;
    populated?: boolean;
    records?: CMSModel[];
};

const CreateModal: React.FC<CreateModalProps> = ({
    btnProps,
    title,
    actions,
    labels,
    postEndpoint,
    refetchRecords,
    populated,
    records
}) => {
    const [gender, setGender] = useState('');
    const [category, setCategory] = useState<any>({})
    const [organArr, setOrganArr] = useState<Array<any>>([]);

    const [open, setOpen] = useState(false);

    //toasting messages
    const toast = useToast();

    //tracking the creation data
    const [values, setValues] = useState<any>({});

    //track the upload progress
    const [uploading, setUploading] = useState<any>({});

    //processing state
    const [processing, setProcessing] = useState(false);

    //temp for add inputs
    const [temp, setTemp] = useState<any>({});
    //video type
    const [videoType, setVideoType] = useState("upload");

    //media type
    const [mediaType, setMediaType] = useState("");

    //Client to use to upload files to AWS S3
    const S3Client = new S3(getAmazonConfig());

    //module name 
    const moduleName = postEndpoint.split("/")[0];
    //current user
    const { user } = useContext(AuthContext);

    //map for input labels that is not quite good
    const labelMap: any = {
        'mediaUrl': 'Media File',
        'imageUrl': 'Image',
        'videoUrl': 'Video',
        'url': 'Audio File'
    }
    const reload = () => window.location.reload();

    const handleVideo = (e: FormEvent) => {
        const target = e.target as HTMLInputElement;
        target.value === "upload" ? setVideoType("upload") : setVideoType("browse");
    }
    //handle the change event on input
    const handleChange = async (e: FormEvent) => {
        const target = e.target as HTMLInputElement;
        if (target.name === 'section') {
            setOrganArr(category['male'][target.value])
        }
        if (target.value === 'male') {
            setGender('male');
            getHealingSections('male')
        } else if (target.value === 'female') {
            setGender('female');
            getHealingSections('female')
        }
        if (target.value === "video") {
            setMediaType("video")
        }
        else if (target.value === "audio") {
            setMediaType("media");
            setVideoType("upload");
        }
        //check if there are files to be uploaded
        if (target.files) {
            if (uploading[target.name]) return;
            setUploading((prevUploading: any) => {
                return { ...prevUploading, [target.name]: true }
            });
            setProcessing(true);
            if (target.files.length > 1) {
                var mediaFiles: Array<string> = [];
                for (const file of target.files) {
                    // const data = await S3Client.uploadFile(file, file.name).catch(() => "Failed to upload image!"));
                    const url = await uploadFile(file, user?.accessToken);
                    if (url) mediaFiles.push(url);
                }
                setUploading((prevUploading: any) => {
                    return { ...prevUploading, [target.name]: false }
                });
                setProcessing(false);
                setValues({ ...values, [target.name]: mediaFiles });
            }
            else {
                const file = target.files[0];
                // const data = await S3Client.uploadFile(file, file.name).catch(() => alert("Failed to upload image!"));
                const url = await uploadFile(file, user?.accessToken);
                if (url) setValues({ ...values, [target.name]: url });
                setUploading((prevUploading: any) => {
                    return { ...prevUploading, [target.name]: false }
                });
                setProcessing(false);
            }
        }
        else {
            //if there are no files just update the value for the field
            setValues({ ...values, [target.name]: target.value });
        }
    }
    const getHealingSections = (value: string) => {
        getHealing(undefined, user?.accessToken).then((res: any) => {
            //map of lists
            setCategory(res);
        })
    }

    //function to handle input of type add (submit)
    const handleAddSubmit = (name: string) => {
        const newValues = values[name] ? [...values[name], temp[name]] : [temp[name]];
        setValues({ ...values, [name]: newValues });
        setTemp({ ...temp, [name]: '' });
        setTimeout(() => document.querySelector('.op-add-cont')!.scrollTop = document.querySelector('.op-add-cont')!.scrollHeight);
    }

    //function to handle input of type add (change)
    const handleAddChange = (e: FormEvent) => {
        const target = e.target as HTMLInputElement;
        setTemp({ ...temp, [target.name]: target.value });
    }

    //function to remove from input of type add
    const handleAddFilter = (name: string, key: string) => {
        const newValues = values[key]?.filter((val: string) => val !== name);
        setValues({ ...values, [key]: newValues })
    }

    //handle submitting the data
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const activeAdd = document.activeElement?.className.split(' ').includes('op-add-input');
        if (processing || activeAdd) return;
        setProcessing(true);
        let valuesPost = values;
        if (values.gender && values.section && values.organ) {
            valuesPost = {
                category: `${values.gender}-${values.section}-${values.organ}`,
                slug: `${values.slug}`
            }
        }
        //The url to submit data to
        const response = await post(postEndpoint, valuesPost, undefined, undefined, user?.accessToken).catch(() => {
            toast({
                title: "Couldn't create a new record..",
                status: "success",
                duration: 4000,
                isClosable: true
            })
        });
        setOpen(false);
        setProcessing(false);
        if (response && response.id) {
            toast({
                title: `Successfully created a new ${moduleName}, please edit its content to view it at the user end.`,
                status: "success",
                duration: 4000,
                isClosable: true
            })
            refetchRecords();
            setTemp({});
            document.querySelectorAll('input').forEach((input: any) => input.value = null);
            document.querySelectorAll('textarea').forEach((input: any) => input.value = null);
        }
        else {
            toast({
                title: "Successfully updated the record!",
                status: "success",
                duration: 4000,
                isClosable: true
            })
        }
    }

    return (
        <>
            <CButton {...btnProps} onClick={() => setOpen(true)} disabled={populated}>
                {btnProps.title}
            </CButton>
            <CModal show={open} onClose={() => {
                setOpen(false);
            }}
                fade
                closeOnBackdrop={false}
            >
                <CModalHeader closeButton>
                    <CModalTitle>
                        <Text textTransform="capitalize" fontSize={18} fontWeight="bold">{title}</Text>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form className='pt-3' onSubmit={onSubmit}>
                        {
                            labels.map(label => (
                                <div key={`${moduleName} ${label.key}`} className='my-3'>
                                    <CLabel htmlFor={label.key} style={{ textTransform: 'capitalize' }}>{label.label ? label.label : (labelMap[label.key] || label.key)}</CLabel>
                                    {
                                        label.type === 'select' ?
                                            <CSelect className='text-capitalize' id={label.key} name={label.key} onChange={handleChange} type={label.type} required multiple={label.multiple}>
                                                <optgroup className='text-capitalize' defaultValue={''}>
                                                    <option value={''}>Please select a(n) {label.key}</option>
                                                    {
                                                        label.key !== 'section' && label.key !== 'organ' ?
                                                            label.options?.map((opt, index) => (
                                                                <option value={opt} key={index}>{opt}</option>
                                                            )) : (label.key === 'section' && category['male']) ?
                                                                Object.keys(category['male']).map((opt, index) => (
                                                                    <option value={opt} key={index}>{opt}</option>
                                                                )) : (organArr.length > 0) &&
                                                                organArr.map((opt, index) => (
                                                                    <option value={opt} key={index}>{opt}</option>
                                                                ))

                                                    }
                                                </optgroup>
                                            </CSelect>
                                            :
                                            label.type === 'add' ?
                                                <div>
                                                    <div style={{ maxHeight: '100px', overflowY: 'auto' }} className='op-add-cont px-2'>
                                                        {
                                                            values[label.key]?.map((val: string) => (
                                                                <h5 key={val} className='text-secondary d-flex justify-content-between align-items-center'>
                                                                    {val}
                                                                    <FaTimes onClick={() => handleAddFilter(val, label.key)} cursor='pointer' />
                                                                </h5>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <CInput id={label.key} name={label.key} onChange={handleAddChange} type="text" onKeyDown={(e: any) => e.code === 'Enter' && handleAddSubmit(label.key)} className='op-add-input' value={temp[label.key]} />
                                                        <CButton className='mx-2 p-0' onClick={() => handleAddSubmit(label.key)}>
                                                            <FaPlusCircle size={24} color='green' />
                                                        </CButton>
                                                    </div>
                                                </div>
                                                :
                                                (label.type !== 'file' && label.type !== 'radio_btn') &&
                                                <>
                                                    <CInput id={label.key} name={label.key} onChange={handleChange} type={label.type} required multiple={label.multiple} placeholder={`Enter ${label?.label}...`} />
                                                    {label.type === 'file' && uploading[label.key] && <h6 style={{ marginTop: '10px', padding: "0 10px" }}>Uploading...</h6>}
                                                </>
                                    }
                                    {label.type === "radio_btn" &&
                                        <div onChange={handleChange}>
                                            <CInput id={label.key} type="radio" value="male" name="gender" style={{ width: "20px", height: "auto", display: "inline" }} />Male
                                            <CInput id={label.key} type="radio" value="female" name="gender" style={{ width: "20px", height: "auto", display: "inline" }} />Female
                                        </div>
                                    }
                                    {label.type === "file" ?
                                        videoType === "upload" ?
                                            <>
                                                <CInput id={label.key} name={label.key} onChange={handleChange} type={label.type} required multiple={label.multiple} />
                                                {label.type === 'file' && uploading[label.key] && <h6 style={{ marginTop: '10px', padding: "0 10px" }}>Uploading...</h6>}
                                            </>
                                            :
                                            <div>
                                                <h6>Url:</h6>
                                                <CInput id={label.key} name={label.key} onChange={handleChange} required multiple={label.multiple} placeholder="YouTube Url"></CInput>
                                            </div>
                                        :
                                        null
                                    }
                                </div>
                            ))
                        }
                        <CModalFooter style={{ marginTop: '30px', paddingBottom: 0 }}>
                            <div className="float-right">{actions}</div>
                        </CModalFooter>
                    </form>
                </CModalBody>
            </CModal>
        </>
    );
};
export default CreateModal;
