import { CButton } from "@coreui/react";
import Filters from "components/Filters";
import useFilters from "components/Filters/useFilters";
import FormInput from "components/FormInput";
import PaginatedTable from "components/PaginatedTable";
import usePagination from "components/PaginatedTable/usePagination";
import useSelect from "components/PaginatedTable/useSelect";
import useSort from "components/PaginatedTable/useSort";
import React, { useState, useEffect, useContext, useCallback } from "react";
import {AuthContext} from 'providers/AuthProvider/AuthProvider';
import {get, post} from 'api/index';
import {useToast} from "@chakra-ui/react";
import { Redirect, useHistory } from "react-router-dom";

const OrdersManagment = () => {
	const [items, setItems] = useState<any[]>([]);
	const [total, setTotal] = useState<number>(1);
	const select = useSelect(setItems);
	const pagination = usePagination();
	const sort = useSort();
	const { filters, onChange, applyFilters, clearFilters } = useFilters([
		'name',
		'email',
		'from',
		'to'
	]);

	//history
	const history = useHistory();

	//current user
	const {user} = useContext(AuthContext);
	//last resolved order
	const [current, setCurrent] = useState<number>();

	//toast messages
	const toast = useToast();

	//method for fetching orders
	const fetchOrders = async (queries?: any) => {
		const queryParams = queries ? '&' + Object.entries(queries).map(([key, value], index, arr) => key ? `${key}=${value}${index < arr.length-1 ? '&' : ''}` : '').join('') : '';
		const URL = `subscription/order?page=${pagination.activePage}&current=${pagination.perPage}${queryParams}`;
		const response = await get<any>(URL, undefined, user?.accessToken);
		console.log(response);
		if (response && Array.isArray(response.result)) {
			const responseG: Array<any> = [];
			response.result.forEach((item: any) => {
				const itemG = {
					id: item.id,
					name: item.user.name,
					phone: item.user.phone,
					email: item.user.email,
					nationalityProofUrl: item.user.nationalityProofUrl,
					period: `${item.quotation.duration} Year(s)`,
					price: item.quotation.price,
					date: new Date(item.date).toLocaleDateString(),
					respondedBy: item.respondedBy? item.respondedBy.name : '',
					paymentMethod: item.paymentMethod,
					status: item.status,
					country: item.country?.name || '',
					proveUrl: item.proveUrl
				}
				responseG.push(itemG);
			})
			setTotal(Math.ceil(response.count / pagination.perPage));
			console.log(responseG);
			setItems(responseG);
		}
		else {
			toast({
                title: "Failed to load data..",
                status: "error",
                duration: 4000,
                isClosable: true
            })
		}
	}

	useEffect(() => {
		fetchOrders();
	}, [pagination.activePage, pagination.perPage, current, filters])

	  //callback to execute when applying filters
	  const filterOrders = useCallback(() => {
		fetchOrders(filters);
	  }, [filters])
	
	  //this adds an event listening to changes in URL to apply filters
	  useEffect(() => {
		const unlisten = history.listen(filterOrders);
		return unlisten;
	  }, [filterOrders])

	if (!user || !['Admin', 'Reviewer'].includes(user.role.name)) return <Redirect to='/login' />

	return (
		<>
			<Filters apply={applyFilters} clear={clearFilters}>
				<FormInput
					name="name"
					value={filters["name"] || ""}
					onChange={onChange}
					label="Name"
				/>
				<FormInput
					name="email"
					value={filters["email"] || ""}
					onChange={onChange}
					label="Email"
				/>

				<FormInput
					type="datetime-local"
					name="from"
					value={filters["from"] || ""}
					onChange={onChange}
					label="From"
				/>

				<FormInput
					type="datetime-local"
					name="to"
					value={filters["to"] || ""}
					onChange={onChange}
					label="To"
				/>
			</Filters>
			<PaginatedTable
				{...select}
				{...pagination}
				{...sort}
				fields={[
					// { key: "_select", label: "", _style: { width: "30px" } },
					"name", "country", "email", "phone",
					"period", "paymentMethod", "price", {key: "proveUrl", label: "Payment Proof"}, {key: "nationalityProofUrl", label: "Nationality Proof"}, "date", "status", "respondedBy",
					"accept", "reject"
				]}
				//actions={actions}
				items={items}
				loading={true}
				user={user}
				setCurrent={setCurrent}
				total={total}
			/>
		</>
	);
};

export default OrdersManagment;