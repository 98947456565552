import { CMSModels } from "views/dashboard/ModelManagment/CMSModels";
import React, {useContext} from 'react';
import {AuthContext} from 'providers/AuthProvider/AuthProvider';

const useNav = () => {

  const {user} = useContext(AuthContext);

  const CMSModelsNav = Object.keys(CMSModels).map((key) => {
    var newKey;
    if (key.includes("_")) {
      newKey = key.replace("_", " ");
    }
    return {
      _tag: "CSidebarNavItem",
      name: newKey? newKey: key,
      to: `/cms/${key}`,
    };
  });

  const getNavAccordingToRole = () => {
    //Admin restrictions (none)
    if (user?.role.name === 'Admin') {
      return [
        { _tag: "CSidebarNavItem", name: "Users", to: "/users" },
        { _tag: "CSidebarNavItem", name: "Orders", to: "/orders" },
        { _tag: "CSidebarNavDropdown", name: "CMS", _children: CMSModelsNav },
      ];
    }
    //Accountant restrictions
    else if (user?.role.name === 'Accountant') {
      return [
        { _tag: "CSidebarNavItem", name: "Users", to: "/users" },
      ];
    }
    //Reviwer restrictions
    else {
      return [
        { _tag: "CSidebarNavItem", name: "Users", to: "/users" },
        { _tag: "CSidebarNavItem", name: "Orders", to: "/orders" },
      ];
    }
  }
  
  const _nav = getNavAccordingToRole();

  return _nav
}

export default useNav
