import UsersManagment from "views/dashboard/UsersManagment";
import OrdersManagment from 'views/dashboard/OrdersManagment';
import Wave from "views/dashboard/Wave";
import Model from 'views/dashboard/ModelManagment/Model';

const routes: any[] = [
  {
    exact: true,
    component: UsersManagment,
    path: "/users",
  },
  {
    exact: true,
    component: OrdersManagment,
    path: '/orders'
  },
  {
    exact: true,
    path: "/cms/:model",
    component: Model,
  }
];
export default routes;
