import { CRow, CCol } from "@coreui/react";
import { render } from "@testing-library/react";
import { Children } from "react";

const FormBlock: React.FC = ({ children }) => {
  return (
    <CRow>
      {Children.map(children, (child, idx) => (
        <CCol md={3} sm={6} xs={12} key={idx}>
          {child}
        </CCol>
      ))}
    </CRow>
  );
};

export default FormBlock;
