import { CButton } from "@coreui/react";
import Filters from "components/Filters";
import useFilters from "components/Filters/useFilters";
import FormInput from "components/FormInput";
import PaginatedTable from "components/PaginatedTable";
import usePagination from "components/PaginatedTable/usePagination";
import useSelect from "components/PaginatedTable/useSelect";
import useSort from "components/PaginatedTable/useSort";
import React, { useState, useEffect, useContext, useCallback } from "react";
import {AuthContext} from 'providers/AuthProvider/AuthProvider';
import { Redirect } from "react-router";
import {useHistory} from 'react-router-dom';
import {get} from 'api/index';
import {useToast} from '@chakra-ui/react';

const actions = (
  <>
    <CButton style={{ marginInlineEnd: "15px" }} color="success">
      Action 1
    </CButton>
    <CButton style={{ marginInlineEnd: "15px" }} color="success">
      Action 2
    </CButton>
    <CButton style={{ marginInlineEnd: "15px" }} color="success">
      Action 3
    </CButton>
  </>
);
const UsersManagment: React.FC = () => {
  const [items, setItems] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(1);
  const select = useSelect(setItems);
  const pagination = usePagination();
  const sort = useSort();
  const { filters, onChange, applyFilters, clearFilters } = useFilters([
    'name',
    'email',
    'from',
    'to'
  ]);

  //current user
  const { user } = useContext(AuthContext);

  //history object
  const history = useHistory();

  //toasting messages
  const toast = useToast();

  //callback to fetchUsers
  const fetchUsers = (queries?: any) => {
    const queryParams = queries ? '&' + Object.entries(queries).map(([key, value], index, arr) => key ? `${key}=${value}${index < arr.length-1 ? '&' : ''}` : '').join('') : '';
    const URL = `user?page=${pagination.activePage}&current=${pagination.perPage}${queryParams}`;
    get(URL, undefined, user?.accessToken)
      .then((res: any) => {
        if (!Array.isArray(res.result)) {
          toast({
            title: "Failed to load data..",
            status: "error",
            duration: 4000,
            isClosable: true
        })
          return;
        }
        //Customize the response to adapt to display fields
        const resG: any[] = [];
        res.result.forEach((item: any) => {
          const itemG = {
            id: item.id,
            name: item.name,
            email: item.email,
            phone: item.phone,
            country: item.country ? item.country.name : "",
            nationalityProofUrl: item.nationalityProofUrl,
            proveUrl: item.activeSubscription?.order?.proveUrl || "",
            activeSubscription: item.activeSubscription,
            paymentMethod: item.activeSubscription && item.activeSubscription.order ? item.activeSubscription.order.paymentMethod : '',
            subscriptionActivationDate: item.activeSubscription ? new Date(item.activeSubscription.startDate).toLocaleDateString() : "",
            period: item.activeSubscription ? `${(new Date(item.activeSubscription.endDate).getFullYear() - new Date(item.activeSubscription.startDate).getFullYear())} Year(s)` : ""
          }
          resG.push(itemG);
        })
        setTotal(Math.ceil(res.count / pagination.perPage));
        setItems(resG);
      })
      .catch(() => {
        toast({
          title: "Failed to load data..",
          status: "error",
          duration: 4000,
          isClosable: true
      })
      });
      
  }

  useEffect(() => {
    fetchUsers();
  }, [pagination.activePage, pagination.perPage])


  //callback to execute when applying filters
  const filterUsers = useCallback(() => {
    fetchUsers(filters);
  }, [filters])

  //this adds an event listening to changes in URL to apply filters
  useEffect(() => {
    const unlisten = history.listen(filterUsers);
    return unlisten;
  }, [filterUsers])

  //Not authenticated admins are retruned to login
  if(!user) return <Redirect to='/login' />

  return (
    <>
      <Filters apply={applyFilters} clear={clearFilters}>
        <FormInput
          name="name"
          value={filters["name"] || ""}
          onChange={onChange}
          label="Name"
        />
        <FormInput
          name="email"
          value={filters["email"] || ""}
          onChange={onChange}
          label="Email"
        />

        <FormInput
          type="datetime-local"
          name="from"
          value={filters["from"] || ""}
          onChange={onChange}
          label="From"
        />

        <FormInput
          type="datetime-local"
          name="to"
          value={filters["to"] || ""}
          onChange={onChange}
          label="To"
        />
      </Filters>
      <PaginatedTable
        {...select}
        {...pagination}
        {...sort}
        fields={[
          "name",
          "email",
          "phone",
          {key: "proveUrl", label: "Payment Proof"},
          {key: "nationalityProofUrl", label: "Nationality Proof"},
          'country',
          "subscriptionActivationDate",
          'paymentMethod',
          "period", 
          "actions"
        ]}
        //actions={actions}
        items={items}
        loading={true}
        user={user}
        total={total}
      />
    </>
  );
};

export default UsersManagment;
