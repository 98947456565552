import { CButton } from "@coreui/react";
import { useLocation } from "react-router-dom";

const CreateButton = () => {
  const location = useLocation();
  return (
    <CButton className="btn btn-success" type='submit'>
      Create
    </CButton>
  );
};
export default CreateButton;
