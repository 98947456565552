export const BASE_URL = 'http://160.153.254.20:3001/admin/';
export const APIEndpoint = 'http://160.153.254.20:3001';

export const get = <T>(endpoint: string, locale?: string, auth_token: string = 'invalid'): Promise<T> =>
	fetch(BASE_URL + endpoint, {
		headers: {
			'Content-Type': 'application/json',
			authorization: `Bearer ${auth_token}`,
			'x-localization': locale || "ar"
		},
	}).then(handleRes).catch(() => null);

	export const getHealing = <T>(locale?: string, auth_token: string = 'invalid'): Promise<T> =>
	fetch(`${BASE_URL}/healing`, {
		headers: {
			'Content-Type': 'application/json',
			authorization: `Bearer ${auth_token}`,
			'x-localization': locale || "ar"
		},
	}).then(handleRes).catch(() => null);

	export const post = <T>(endpoint: string, body: T, locale?: string, method: string = 'POST', auth_token: string = 'invalid'): Promise<T> =>
	fetch(BASE_URL + endpoint, {
		method: method || 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: `Bearer ${auth_token}`,
			'x-localization': locale || 'ar'
		},

		body: JSON.stringify(body),
	}).then(handleRes).catch(() => null);


export const put = <T>(endpoint: string, body: T, locale?: string, auth_token: string = 'invalid'): Promise<T> =>
	fetch(BASE_URL + endpoint, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			authorization: `Bearer ${auth_token}`,
			'x-localization': locale || "ar"
		},

		body: JSON.stringify(body),
	}).then(handleRes).catch(() => null);

export const deleteMethod = (endpoint: string, auth_token: string = 'invalid'): Promise<void> =>
	fetch(BASE_URL + endpoint, {
		method: 'delete',
		headers: {
			'Content-Type': 'application/json',
			authorization: `Bearer ${auth_token}`,
			'x-localization': 'ar'
		},
	}).then(handleRes).catch(() => null);

const handleRes = async (res: Response) => {
	if (res.status === 401 && window.location.pathname !== '/login') {
		window.localStorage.clear();
		window.location.reload();
	}
	try {
		const jsonRes = await res.json();
		if (!res.ok) throw jsonRes;
		return jsonRes;
	}
	catch (e) {
		return new Promise(resolve => resolve(res.ok));
	}
};

export const getAmazonConfig = () => {
	//Update these values before production
	return {
		bucketName: "BUCKET-NAME", //The bucket name you are using to save images
		dirName: "DIR-NAME", //optional, The dir to save the iamge to
		region: "REGION", //The region for AWS eg. eu-west-1
		accessKeyId: "ACCESS-KEY", //Your AWS Access key 
		secretAccessKey: "SECRET-KEY", //You AWS Secrey Key
		s3Url: "S3-BUCKET-URL" //optional, Custom S3 Url
	}
}

//Endpoint for media
export const MEDIA_URL = 'http://160.153.254.20:3000';
const MediaAPIEndpoint = 'http://160.153.254.20:3000/media';
export const uploadFile = async (file: File, auth_token: string = 'invalid') => {
	//data object to send to server
	const data = new FormData();

	//append the file object to the data
	data.append('file', file);

	//uploading file to the web server
	const response = await fetch(MediaAPIEndpoint, {
		headers: {
			Authorization: `Bearer ${auth_token}`,
		},
		method: 'POST',
		body: data
	});

	//get the json
	const json = await response.json();
	//check the data
	if (json.url) return `${MEDIA_URL}/${json.url}`;
	else {
		alert("Failed to upload file!")
	}
}
