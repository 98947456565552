import { isEmpty } from "lodash";
import { stringify, parse } from "query-string";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import getSortFromURL from "./getSortFromURL";
import { SortValue } from "./types";

const useSort = () => {
  const [sortValue, setSort] = useState<SortValue>(getSortFromURL());
  const history = useHistory();
  const sortBy = (sortValue: SortValue) => {
    setSort(sortValue);
    if (!isEmpty(sortValue))
      history.replace({
        ...history.location,
        search: stringify({
          ...parse(history.location.search),
          sort: JSON.stringify(sortValue),
        }),
      });
  };
  return { sortValue, sortBy };
};

export default useSort;
