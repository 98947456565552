type HeaderProps = {
  title: string;
  actions: JSX.Element;
};
const Header: React.FC<HeaderProps> = ({ title, actions }) => {
  return (
    <header>
      <h2 className="d-inline-block text-capitalize h2">{title}</h2>
      <nav className="float-right">{actions}</nav>
    </header>
  );
};
export default Header;
