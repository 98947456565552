import { FiltersValue } from "components/PaginatedTable/types";
import queryString from "query-string";

const getFiltersFromURL = (filterKeys: string[]) => {
  const search = window.location.search;
  const initialFiltersFromUrl = queryString.parse(search).filters;
  let initialFilters: FiltersValue = {};
  if (initialFiltersFromUrl) {
    const parsedFilters = JSON.parse(initialFiltersFromUrl as string);
    for (const key of filterKeys) {
      initialFilters[key] = parsedFilters[key];
    }
  }
  return initialFilters;
};
export default getFiltersFromURL;
