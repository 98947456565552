import {getHealing} from 'api/index';
import {AuthContext} from 'providers/AuthProvider/AuthProvider';
import { StringifyOptions } from 'querystring';
import React, { useState, useEffect, useContext, useCallback } from "react";

//Type for the CMSModels
//Note: Coming from response
export interface CMSModel {
    id?: number;
    slug?: string;
    name?: string;
    imageUrl?: string;
    active?: boolean;
    date?: string;
    content?: string;
    locale?: string;
    translations?: Array<{locale?: string, content?: string, name?: string, imageUrl?: string, description?: string, title?: string, speciality?: string, url?: string, mediaFiles?: Array<{url: string; id: number; name: string;}>, type?: string, affirmations?: string, benefits?: string, guides?: string}>
    mediaUrl?: string;
    description?: string;
    title?: string;
    speciality?: string;
    url?: string;
    videoUrl?: string;
    frequency?: number;
    category?: string;
    organ?: string;
    section?: string;
    imageTFT?: string;
    type?: string;
    videoTFT?: string;
    _classes?: string;
    benefits?: string;
    guides?: string;
    mediaFiles?: Array<{url: string; id: number; name: string}>;
    media?: Array<{id: string; url: string}>;
}



//Note requiredFields are the fields required at the creation stage
//Display fields are fields to be displayed at the table
//Fields are the fields to be populated in the editing stage
//Populated field means that the model comes populated with entries thus create and delete actions are not avaialable
//Common means that content field is not split into Ar Content, En Content, etc.. (locale independent)
export const CMSModels = {
    Training:{
        title: "Add Training",
        requiredFields:[{key: "slug", type: "text", label: "Training Name"}],
        displayFields: [{key: "slug", label: "Training Name"}],
        fields:[
            {key: "slug", type: "text", required: false, label: "Training Name"},
            {key: "title", type: "text", required: true},
            {key:"url", type:"file",  required: false}
        ],
        postEndpoint: 'training',
        putEndpoint: 'training/{id}',
        putMethod: 'POST',
        getEndpoint: 'training',
        deleteEndpoint: 'training/{id}',
        postEditEndpoint: "training/{id}",
        populated: false,
        common: false,
    },
    Learning:{
        title: "Add Learning",
        requiredFields:[{key: "slug", type: "text",  label: "Learning Name"}],
        displayFields:[{key: "slug", label: "Learning Name"}],
        fields:[
            {key: "slug", type: "text", required: false, label: "Learning Name"},
            {key: 'title', type: 'text', required: true},
            {key: "description", type: "text", required: true},
            {key: "speciality", type: "text", required: true},
            {key:"type", type:"select", options:["video", "audio"], required: true}, 
            {key:"url", type:"file",  required: true}
        ],
        postEndpoint: 'learning',
        putEndpoint: 'learning/{id}',
        putMethod: 'POST',
        getEndpoint: 'learning',
        deleteEndpoint: 'learning/{id}',
        postEditEndpoint: "learning/{id}",
        populated: false,
        common: false,
    },
    Healing: {
        title: "Add Disease",
        requiredFields: [{key: "gender", type: "radio_btn"}, {key: "section", type: "select"}, {key:"organ", type:"select"}, {key:"slug", type:"text", label: "Healing Name"}],
        displayFields: [{key: "slug",  label: "Healing Name"}, {key: "organ"}],
        fields: [
            {key: "slug", type:"text", required: false, label: "Healing Name"},
            {key: "name", type: "text", required: true},
            {key: "description", type: "text", required: true},
            {key: "affirmations", type: 'add', required: true},
        ],
        postEndpoint: 'healing',
        putEndpoint: 'healing/{id}',
        putMethod: 'POST',
        getEndpoint: 'healing',
        deleteEndpoint: 'healing/{id}',
        populated: false,
        common: false,
    },
    TFT: {
        title: "Add TFT",
        requiredFields: [{key: "imageUrl", type: "file"}, {key: "videoUrl", type: 'file'}],
        displayFields: [{key: "name"}],
        fields: [
            {key: "imageTFT", type: "file", required: false},
            {key: "videoTFT", type: "file", required: false},
            {key: "article", type:"add", required: true}

        ],
        postEndpoint: 'tft',
        putEndpoint: 'tft/{id}',
        putMethod: 'PUT',
        getEndpoint: 'tft',
        deleteEndpoint: 'tft/{id}',
        populated: true,
        common: false,
    },
    Chakra: {
        title: "Add Chakra",
        requiredFields: [{key: "slug", type:"text", label: "Chakra Name"}, {key: "imageUrl", type: "file"}, {key: 'videoUrl', type: 'file'}],
        displayFields: [{key: "slug", label: "Chakra Name"}],
        fields: [
            {key: "slug", type: "text", required: false, label: "Chakra Name", disabled: true},
            {key: "imageUrl", type: "file", required: false},
            {key: "videoUrl", type: "file", required: false},
            {key: 'name', type: 'text', required: true}
        ],
        postEndpoint: 'chakra',
        putEndpoint: 'chakra/{id}',
        putMethod: 'POST',
        getEndpoint: 'chakra',
        deleteEndpoint: 'chakra/{id}',
        postEditEndpoint: "training/{id}",
        populated: true,
        common: false,
    },
    Solfeggio: {
        title: "Add Solfeggio",
        requiredFields: [{key: "videoUrl", type: "file"}],
        displayFields: [{key: "frequency"}],
        fields: [
            {key: "videoUrl", type: "file", required: false},
        ],
        postEndpoint: 'solfeggio',
        putEndpoint: 'solfeggio/{id}',
        putMethod: 'PUT',
        getEndpoint: 'solfeggio',
        deleteEndpoint: 'solfeggio/{id}',
        populated: true,
        common: true,
    },
    Waves: {
        title: "Add Wave",
        requiredFields: [{key: "slug", type: "text", label:"Wave Name"}],
        displayFields: [{key: "slug", label: "Wave Name"}],
        fields: [
            {key: "slug", type: "text", required: false, label: "Wave Name"},
            {key: "name", type: "text", required: true, label: " Title"},
            {key: "description", type: "text", required: false},
            {key: "active", type: "checkbox", required: false},
            {key: "imageUrl", type: "file", required: false},
            {key: "benefits", type: "textArea", required: true},
            {key: "guides", type: "textArea", required: true},
            {key: "mediaFiles", type: "file", required: false, multiple: true},
            {key: 'audioFilesText', label: "Audio Files Text", required: true, type: "textArea"}
        ],
        postEndpoint: 'wave',
        putEndpoint: 'wave/{id}',
        putMethod: 'POST',
        getEndpoint: 'wave',
        deleteEndpoint: 'wave/{id}',
        populated: false,
        common: false,
    },
    Balance: {
        title: "Add Balance",
        requiredFields: [{key: "url", type: "file"}],
        displayFields: [{key: "url"}],
        fields: [
            {key: "url", type: "file", required: false},
            {key: "name", type: "text", required: true},
        ],
        postEndpoint: 'balance',
        putEndpoint: 'balance/{id}',
        putMethod: 'POST',
        getEndpoint: 'balance',
        deleteEndpoint: 'balance/{id}',
        populated: false,
        common: false,
    },
    Manifestation: {
        title: "Add Manifestation",
        requiredFields: [{key: "mediaUrl", type: "file"}],
        displayFields: [{key: "mediaUrl"}],
        fields: [
            {key: "mediaUrl", type: "file", required: false},
            {key: "name", type: "text", required: true},
            {key: "description", type: "textArea", required: true},
        ],
        postEndpoint: 'manifestation',
        putEndpoint: 'manifestation/{id}',
        putMethod: 'POST',
        getEndpoint: 'manifestation',
        deleteEndpoint: 'manifestation/{id}',
        populated: false,
        common: false,
    },
   Main_Sections:{
    title: "Add content to main sections",
    requiredFields: [{key: "videoUrl", type: "file"}],
    displayFields: [{key: "slug", label:"Main Section", type: 'textArea'}],
    fields: [
        {key: "content", type: "text", required: true},
    ],
    postEndpoint: 'description',
    putEndpoint: 'description/{slug}',
    putMethod: 'PUT',
    getEndpoint: 'description',
    deleteEndpoint: 'description/{id}',
    populated: true,
    common: false,
   }
}
