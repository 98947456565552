import { useState } from "react";
import { useHistory } from "react-router-dom";
import getFiltersFromURL from "./getFiltersFromURL";
import queryString from "query-string";
import { isEmpty } from "lodash";
import { FiltersValue } from "components/PaginatedTable/types";
const useFilters = (filterKeys: string[]) => {
  const [filters, setFilters] = useState<FiltersValue>(
    getFiltersFromURL(filterKeys)
  );
  const history = useHistory();
  const applyFilters = () => {
    if (isEmpty(filters)) return;
    history.replace({
      ...history.location,
      search: queryString.stringify({
        ...queryString.parse(history.location.search),
        filters: JSON.stringify(filters),
      }),
    });
  };
  const clearFilters = () => {
    setFilters({});
    const { filters, ...searchWithoutFilters } = queryString.parse(
      history.location.search
    );
    history.replace({
      ...history.location,
      search: queryString.stringify({
        ...searchWithoutFilters,
      }),
    });
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFilters((old) => {
      return { ...old, [e.target.name]: e.target.value };
    });
  return { filters, onChange, applyFilters, clearFilters };
};
export default useFilters;
