import { defaultPerPageIndex, perPageOptions } from "config";
import { stringify, parse } from "query-string";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import getPaginationFromURL from "./getPaginationFromURL";
import { PaginationValue } from "./types";

const usePagination = () => {
  const [pagination, setPagination] = useState<PaginationValue>(
    getPaginationFromURL()
  );
  const history = useHistory();
  useEffect(() => {
    if (
      pagination !==
      {
        page: 1,
        perPage: perPageOptions[defaultPerPageIndex],
      }
    )
      history.replace({
        ...history.location,
        search: stringify({
          ...parse(history.location.search),
          pagination: JSON.stringify(pagination),
        }),
      });
  }, [history, pagination]);
  const setActivePage = (page: number) => {
    setPagination((old) => {
      return { ...old, page };
    });
  };
  const setPerPage = (perPage: number) => {
    setPagination((old) => {
      return { ...old, perPage: perPage };
    });
  };
  return {
    activePage: pagination.page,
    setActivePage,
    perPage: pagination.perPage,
    setPerPage,
    pagination: true,
  };
};
export default usePagination;
