import PaginatedTable from "components/PaginatedTable";
import Header from "components/Header";
import CreateButton from "components/CreateButton";
import CreateModal from "./CreateModal";
import Form from "components/Form";
import { CMSModels, CMSModel } from './CMSModels';
import React, { useEffect, useState } from 'react';
import { get } from "api";
import DataTable from './DataTable';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider/AuthProvider';
import {Box, useToast} from "@chakra-ui/react";

interface ModelProps {
    match: { params: { model: keyof typeof CMSModels } };
}

const Model: React.FC<ModelProps> = ({ match }) => {

    //This is the model corresponding to the model name in the url matched
    //CMSModels is the main models scehma
    const [MODEL, setMODEL] = useState(CMSModels[match.params.model]);

    //toasting messages
    const toast = useToast();

    //the state for tracking the records
    const [records, setRecords] = useState<CMSModel[]>([]);
    const [currentModel, setCurrentModel] = useState<number | null>(null);

    //current user
    const { user } = useContext(AuthContext);

    //Some names are not user friendly
    const userFriendlyTitle = {
        tap: "Tapping Chart",
        "9g": "9G Steps"
    }


    //method to fetch the data for the current model
    const fetchRecords = async () => {
        let response = await get<Array<CMSModel>>(MODEL.getEndpoint, undefined, user?.accessToken).catch(() => {
            toast({
                title: "Failed to load data..",
                status: "error",
                duration: 4000,
                isClosable: true
            })
        });
        if (response && Array.isArray(response)) {
            for (const entry of response) {
                if (entry.name) {
                    entry.name = userFriendlyTitle[entry.name as keyof typeof userFriendlyTitle] || entry.name;
                }
            }
            setRecords(response);
        }
        else {
            toast({
                title: "Failed to load data..",
                status: "error",
                duration: 4000,
                isClosable: true
            })
        }
    }


    const history = useHistory();
    //listen for path changes to remove records
    useEffect(() => {
        const unlisten = history.listen((loc) => {
            // setRecords([]);
            const currentPaths: any = loc.pathname.split('/');
            const key: keyof typeof CMSModels = currentPaths[currentPaths.length - 1];
            //make sure that key retrieved is equal to one of the models keys
            if (Object.keys(CMSModels).includes(key)) {
                setMODEL(CMSModels[key]);
            }
        });
        return () => unlisten();
    }, [history])

    useEffect(() => {
        //once the component is mounted fetch the records for the current model
        fetchRecords();
        // Specify how to clean up after this effect:
        return function cleanup() {
            setRecords([]);
        };
    }, [MODEL])


    return (
        <>
            {
                MODEL ?
                    <>
                        <Box padding="10px 0">
                            <Header
                                title={match.params.model.includes("_")? match.params.model.replace("_", " "): match.params.model}
                                actions={
                                    <>
                                        <CreateModal
                                            title={MODEL.title}
                                            btnProps={{ color: "success", title: "Create" }}
                                            actions={<CreateButton />}
                                            labels={MODEL.requiredFields}
                                            postEndpoint={MODEL.postEndpoint}
                                            refetchRecords={fetchRecords}
                                            populated={MODEL.populated}
                                            records={records}
                                        />
                                    </>
                                }
                            />
                        </Box>
                        {/* <PaginatedTable fields={MODEL.displayFields} items={records} loading={false} /> */}
                        <DataTable
                            fields={MODEL.common ? [...MODEL.displayFields, { key: 'Content' }, { key: 'Delete' }] : [...MODEL.displayFields, { key: 'ArContent' }, { key: 'EnContent' }, { key: 'Delete' }]}
                            items={records}
                            inputFields={MODEL.fields}
                            putEndpoint={MODEL.putEndpoint}
                            deleteEndpoint={MODEL.deleteEndpoint}
                            refetchRecords={fetchRecords}
                            populated={MODEL.populated}
                            putMethod={MODEL.putMethod}
                            postEditEndpoint={MODEL.postEndpoint}
                            common={MODEL.common}

                        />
                    </>
                    :
                    <>
                        <div>
                            <p>No Model Found</p>
                        </div>
                    </>
            }
        </>
    )


}


export default Model