import React, {useState} from 'react';
import {CDataTable, CButton, CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CInput, CLabel} from '@coreui/react';
import ModelEdit from './ModelEdit';
import {currentLocales, Locale} from 'providers/LocaleProvider/Locales';
import {CMSModel} from './CMSModels';
import ModelDelete from './ModelDelete';
import { forEachChild } from 'typescript';



interface DataTableProps {
    fields: Array<{key: string, label?: string}>;
    items: Array<CMSModel>;
    inputFields: Array<{key: string, type: string, required: boolean}>;
    putEndpoint: string;
    deleteEndpoint: string;
    refetchRecords: () => void;
    populated?: boolean;
    putMethod?: string;
    common?: boolean;
    postEditEndpoint?: string;
}
const margins = {margin: "0 10px 0 10px"}

const DataTable: React.FC<DataTableProps> = ({fields, items, putEndpoint, inputFields, deleteEndpoint, refetchRecords, populated, putMethod, common, postEditEndpoint}) => {
    const[organ, setOrgan]= useState({
      male: "", 
      female: ""
    });
    //Slots for all locales where every slot changes the content corresponding
    //to it's local only for each item
    let male = [];
    let female = [];
    var item;
    const updateField = (e: any) => {
      setOrgan({
        ...organ,
        [e.target.name]: e.target.value
      });
    };
    for(var i in items){
        item = items[i];
        if(putEndpoint.split("/")[0] === 'healing'){
          item.section = item.category?.split("-")[1];
          item.organ = item.category?.split("-")[2];
          var gender = item.category?.split("-")[0];
          if(gender === "male" && item.section === organ.male){
            male.push(item);
          }
          else if(gender === "female" && item.section === organ.female){
            female.push(item);
          }
      }
      if(item.videoUrl === "" || item.mediaUrl === "" || item.imageUrl === ""){
        items[i]._classes = "table-danger"
      }
      
    }
    let slots = {}
    if (common) {
        slots = {Content: (item: CMSModel) => (
            <ModelEdit key={item.id} refetchRecords={refetchRecords} putMethod={putMethod} locale="en" item={item} putEndpoint={putEndpoint} inputFields={inputFields} postEditEndpoint={postEditEndpoint} populated={populated}/>
        )}
    }
    else {
        currentLocales.forEach((loc: Locale) => {
            slots = {...slots, [loc.label]: (item: CMSModel) => (
                <ModelEdit key={item.id} refetchRecords={refetchRecords} putMethod={putMethod} locale={loc.key} item={item} putEndpoint={putEndpoint} postEditEndpoint={postEditEndpoint} inputFields={inputFields} populated={populated}/>
            )}
        })
    }
    slots = {...slots, Delete: (item: CMSModel) => <ModelDelete key={item.id} populated={populated} refetchRecords={refetchRecords} deleteEndpoint={deleteEndpoint} item={item}/>}
/*     const filteredDateItems = usersData.filter(item => {
      return item.registered;
    }); */
    return (
        <div className='bg-white p-3' style={{borderRadius: '5px', color:"#3c4b64"}}>
            {putEndpoint.split("/")[0] === "healing"?
                <CTabs activeTab="male">
                <CNav variant="tabs">
                  <CNavItem>
                    <CNavLink data-tab="male">
                      Male
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink data-tab="female">
                      Female
                    </CNavLink>
                  </CNavItem>
                </CNav>
                <CTabContent>
                  <CTabPane data-tab="male">
                  <div style={{
                      margin: "10px 0"
                    }}>
                      <CLabel style={margins}>Filter: </CLabel>
                       <input id="head" type="radio" value="head" name="male" onClick={updateField} style={margins} checked={organ.male === "head"}/>head  
                       <input id="abdomen" type="radio" value="abdomen" name="male" onClick={updateField} style={margins} checked={organ.male === "abdomen"}/>abdomen    
                       <input id="legs "type="radio" value="limps" name="male" onClick={updateField} style={margins} checked={organ.male === "limps"}/>limps
                     </div>    
                        <CDataTable
                        fields={fields}
                        items={male}
                        //Add all the current locales avaialble for edit here
                        scopedSlots={slots}
                    ></CDataTable>
                    </CTabPane>
                  <CTabPane data-tab="female">
                    <div style={{
                      margin: "10px 0"
                    }}>
                      <CLabel style={margins}>Filter: </CLabel>
                       <input id="head" type="radio" value="head" name="female" onClick={updateField} style={margins} checked = {organ.female === "head"}/>head  
                       <input id="abdomen" type="radio" value="abdomen" name="female" onClick={updateField} style={margins} checked = {organ.female === "abdomen"}/>abdomen    
                       <input id="legs" type="radio" value="limps" name="female" onClick={updateField} style={margins} checked = {organ.female === "limps"}/>limps
                     </div>    
                  <CDataTable
                        fields={fields}
                        items={female}
                        //Add all the current locales avaialble for edit here
                        scopedSlots={slots}
                        
                    />
                  </CTabPane>
                </CTabContent>
              </CTabs>
            :
            <CDataTable
            fields={fields}
            items={items}
            //Add all the current locales avaialble for edit here
            scopedSlots={slots}
        />
            }
        </div>
    )
}

export default DataTable;