import { CFormGroup, CInput, CLabel } from "@coreui/react";
import {useState, FormEvent} from 'react';

const FormInput: React.FC<CInput> = ({ label, ...props }) => {

  return (
    <CFormGroup>
      <CLabel htmlFor={props.id}>{label}</CLabel>
      <CInput {...props}/>
    </CFormGroup>
  );
};
export default FormInput;
